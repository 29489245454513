@import "../styles";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-right: 60px;
  padding-left: 60px;

  background: #dfdfd5;
}

.image {
  max-width: $mobileS;
}

.text {
  margin-bottom: 60px;

  text-align: center;

  font-family: Arial, Verdana, san-serif;
  font-size: 20px;
  color: #8c949a;
}

.link {
  color: #404f57;
}
